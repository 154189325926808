import { getFeedTitle } from '@wix/communities-blog-client-common';
import { createSelector } from 'reselect';
import { getViewerAllPostsLabelTranslation } from '../../common/selectors/viewer-all-post-label-translation-selectors';
import { getShowAllPostsCategory } from '../selectors/category-menu-selectors';

const getCategoryMenuWidgetData = (state) => state.categoryMenuWidget;

export const getCategoryMenu = createSelector(
  getCategoryMenuWidgetData,
  getShowAllPostsCategory,
  getFeedTitle,
  getViewerAllPostsLabelTranslation,
  (categoryMenu, showAllPosts, title, translatedTitle) => {
    if (showAllPosts) {
      categoryMenu.categoryMenu[0].text = translatedTitle || title;
    } else {
      categoryMenu.categoryMenu.slice(1);
    }
    return categoryMenu;
  },
);
